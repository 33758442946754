import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { showErrorToast } from '@utils/index';

import DataTable from '../../../shared/organisms/DataTable/DataTable';
import { QuoteManagementTablesProps } from '../types';
import { TableWrapper } from '../style';
import {
  QUOTE_MANAGEMENT_TABS,
  getList,
  getQuoteManagementTableColumns,
  initialFilters,
  mapTableData
} from './helper'; //getList,mapTableData

function QuoteManagementTable(props: QuoteManagementTablesProps) {
  const { activePanel, currentPanel, startDate, endDate, callApi, setCallApi } = props;
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>();
  const [totalCount, setTotalCount] = useState<Number>(0);
  const [pageSize, setPageSize] = useState<Number>(0);
  const navigate = useNavigate();
  const localStorageKeys = [
    'account_manager',
    'customer_name',
    'event_date',
    'event_manager',
    'event_name',
    'event_staffs',
    'event_time',
    'guests',
    'kitchen_staff',
    'quotation_no',
    'venue_name'
  ];

  const initialFilters = {
    total_amount: undefined,
    status: QUOTE_MANAGEMENT_TABS.ALL,
    page: 1
  };

  for (const key of localStorageKeys) {
    //@ts-expect-error
    initialFilters[key] = localStorage.getItem(`quote_${key}`) ?? '';
  }

  const [filters, setFilters] = useState<any>(initialFilters);
  useEffect(() => {
    if (currentPanel === activePanel || callApi) {
      setCallApi(false);
      const dataToUpdate = {
        ...filters,
        page: 1,
        status: activePanel,
        event_start_date: startDate,
        event_end_date: endDate
      };

      if (!dataToUpdate.event_date) {
        dataToUpdate.event_date = new Date().toISOString().slice(0, 10);
      }

      updateTableData(dataToUpdate);
    }
  }, [activePanel, callApi]);

  const updateTableData = async (filters: any) => {
    setIsTableLoading(true);
    try {
      const newFilters = { ...filters };
      setFilters(newFilters);
      const res = await getList(newFilters);
      if (res?.status) {
        setTotalCount(res?.list?.total);
        setPageSize(res?.list?.per_page);
        const mappedData: any = mapTableData(res?.list?.data);
        setTableData(mappedData);
        setNextPageUrl(res?.list?.next_page_url);
      }
    } catch (error: any) {
      showErrorToast({
        message: 'Failed!',
        description: 'Failed to fetch list data'
      });
    }
    setIsTableLoading(false);
  };

  const searchFilter = useCallback(
    debounce(async (filters: any) => {
      await updateTableData(filters);
    }, 500),
    []
  );

  const columns = getQuoteManagementTableColumns(
    activePanel,
    filters,
    setFilters,
    updateTableData,
    searchFilter
  );

  return (
    <TableWrapper>
      <DataTable
        loading={isTableLoading}
        columns={columns}
        nextPageURL={nextPageUrl}
        dataSource={tableData}
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: filters?.page,
          onChange: (page: any) => {
            const newFilters = { ...filters, page: page };
            setFilters(newFilters);
            updateTableData(newFilters);
          }
        }}
        renderEmpty={() => {
          return <>No Quotes Found.</>;
        }}
        onRowClick={(e: any) => navigate(`/quote/view/${e?.record?.id}`)}
      />
    </TableWrapper>
  );
}

export default QuoteManagementTable;
