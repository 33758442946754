import { Button } from '@atom/Buttons';
import DatePickerComponent from '@atom/DatePicker';
import { Flex } from '@atom/Flex';
import { SearchBar } from '@atom/Search';
import SelectInput from '@atom/Select';
import TimePickerComponent from '@atom/TimePicker';
import services from '@services/index';
import { formatCurrency } from '@utils/format';
import { getSelectOptions } from '@utils/helper';
import { STATUS_NAME_COLORS } from '../constants';
import QuoteManagementTable from './QuoteManagementTable';
import { Moment } from 'moment';
import TableHeader from '@atom/TableHeader';

export const totalAmountOptions = [
  {
    id: 1,
    name: 'Low - High'
  },
  {
    id: 2,
    name: 'High - Low'
  },
  {
    id: 3,
    name: 'Under $10,000'
  },
  {
    id: 4,
    name: 'Under $50,000'
  },
  {
    id: 5,
    name: 'Over $50,000'
  }
];

export enum QUOTE_MANAGEMENT_TABS {
  ALL = 'all',
  DRAFTS = '0',
  APPROVED = '3',
  REJECTED = '4',
  CREATED_EVENTS = '5',
  CONFIRMED_EVENTS = '7',
  CANCELED_EVENTS = '8',
  PENDING_APPROVAL = 'pending_approval'
}

export const initialFilters = {
  account_manager: undefined,
  customer_name: undefined,
  event_date: undefined,
  event_manager: undefined,
  event_name: undefined,
  event_staffs: undefined,
  event_time: undefined,
  guests: undefined,
  kitchen_staff: undefined,
  quotation_no: undefined,
  total_amount: undefined,
  venue_name: undefined,
  status: QUOTE_MANAGEMENT_TABS.ALL,
  page: 1
};

export const getQuoteManagementTabs = (
  activePanel: string,
  startDate: string,
  endDate: string,
  callApi: boolean,
  setCallApi: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const quoteManagementTabs = [
    {
      tab: 'ALL',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.ALL}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.ALL
    },
    {
      tab: 'APPROVED',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.APPROVED}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.APPROVED
    },
    {
      tab: 'CREATED EVENTS',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.CREATED_EVENTS}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.CREATED_EVENTS
    },
    {
      tab: 'CONFIRMED EVENTS',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.CONFIRMED_EVENTS}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.CONFIRMED_EVENTS
    },
    {
      tab: 'PENDING APPROVAL',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.PENDING_APPROVAL}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.PENDING_APPROVAL
    },
    {
      tab: 'DRAFTS',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.DRAFTS}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.DRAFTS
    },
    {
      tab: 'REJECTED',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.REJECTED}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.REJECTED
    },
    {
      tab: 'CANCELED EVENTS',
      content: (
        <QuoteManagementTable
          activePanel={activePanel}
          currentPanel={QUOTE_MANAGEMENT_TABS.CANCELED_EVENTS}
          startDate={startDate}
          endDate={endDate}
          setCallApi={setCallApi}
          callApi={callApi}
        />
      ),
      key: QUOTE_MANAGEMENT_TABS.CANCELED_EVENTS
    }
  ];

  return quoteManagementTabs;
};

const handleUpdateFilter = (
  filters: any,
  setFilters: (filters: any) => void,
  key: string,
  value: any,
  fetchData: (filters: any) => void
) => {
  const newFilters = { ...filters, page: 1, [`${key}`]: value };
  setFilters(newFilters);
  fetchData(newFilters);
};

export const getQuoteManagementTableColumns = (
  activePanel: string,
  filters: any,
  setFilters: (filters: any) => void,
  updateTableData: (filters: any) => void,
  searchFilter: (filters: any) => void
) => {
  return [
    {
      title: (
        <TableHeader
          style={{ width: '150px' }}
          columnTitle="Quote Number"
          name="quotation_no"
          value={filters?.quotation_no ?? ''}
          placeholder="Quote #"
          onChange={(e: any) => {
            localStorage.setItem('quote_quotation_no', e.target.value);
            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'quotation_no',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'quotation_no',
      width: '20%',
      render: (data: any) => {
        return data ? `#${data}` : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '150px', paddingTop: '1px' }} gap={15}>
          <Flex justifyContent="space-between">
            Event Date
            {filters?.event_date ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px', fontWeight: '600' }}
                onClick={() => {
                  localStorage.removeItem('quote_event_date');
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'event_date',
                    undefined,
                    searchFilter
                  );
                }}
              />
            ) : null}
          </Flex>
          <DatePickerComponent
            id={'event_date'}
            name={'event_date'}
            format={'YYYY-MM-DD'}
            placeholder={'DD/MM'}
            value={filters?.event_date}
            allowClear={false}
            onChange={(e: any) => {
              localStorage.setItem('quote_event_date', e);

              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'event_date',
                e,
                searchFilter
              );
            }}
          />
        </Flex>
      ),
      dataIndex: 'event_date',
      width: '20%',
      render: (data: any) => {
        return data && data !== '0000-00-00' ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '150px' }}
          columnTitle="Client"
          name="customer_name"
          value={filters.customer_name ?? ''}
          placeholder="Client"
          onChange={(e: any) => {
            localStorage.setItem('quote_customer_name', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'customer_name',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'customer_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="Event Name"
          name="event_name"
          value={filters.event_name ?? ''}
          placeholder="Event Name"
          onChange={(e: any) => {
            localStorage.setItem('quote_event_name', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'event_name',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'event_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '110px' }}
          columnTitle="Guests"
          name="guests"
          value={filters.guests ?? ''}
          placeholder="Guest"
          onChange={(e: any) => {
            localStorage.setItem('quote_guests', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'guests',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'guests',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? data : 0;
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '240px' }}
          columnTitle="Venue"
          name="venue_name"
          value={filters.venue_name ?? ''}
          placeholder="Venue"
          onChange={(e: any) => {
            localStorage.setItem('quote_venue_name', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'venue_name',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'venue_name',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '180px', paddingTop: '1px' }} gap={15}>
          <Flex justifyContent="space-between">
            Time
            {filters?.event_time ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() => {
                  localStorage.removeItem('quote_event_time');

                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'event_time',
                    undefined,
                    searchFilter
                  );
                }}
              />
            ) : null}
          </Flex>
          <TimePickerComponent
            id={'event_time'}
            name={'event_time'}
            use12Hours={true}
            placeholder={'HH:MM'}
            format={'h:mm A'}
            allowClear={false}
            value={filters?.event_time}
            onChange={(e: any) => {
              localStorage.setItem('quote_event_time', e);
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'event_time',
                e,
                searchFilter
              );
            }}
            onSelect={(e: Moment) => {
              localStorage.setItem('quote_event_time', e.format('hh:mm A'));
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'event_time',
                e.format('hh:mm A'),
                searchFilter
              );
            }}
          />
        </Flex>
      ),
      dataIndex: 'event_time',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '240px' }}
          columnTitle="Account Manager"
          name="account_manager"
          value={filters.account_manager ?? ''}
          placeholder="Account Manager"
          onChange={(e: any) => {
            localStorage.setItem('quote_account_manager', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'account_manager',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'account_manager',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="All Kitchen Staff"
          name="kitchen_staff"
          value={filters.kitchen_staff ?? ''}
          placeholder="All Kitchen Staff"
          onChange={(e: any) => {
            localStorage.setItem('quote_kitchen_staff', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'kitchen_staff',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'kitchen_staff',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '200px' }}
          columnTitle="All Operations Staff"
          name="event_staffs"
          value={filters.event_staffs ?? ''}
          placeholder="All Operations Staff"
          onChange={(e: any) => {
            localStorage.setItem('quote_event_staffs', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'event_staffs',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'event_staffs',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <TableHeader
          style={{ width: '180px' }}
          columnTitle="Event Manager"
          name="event_manager"
          value={filters.event_manager ?? ''}
          placeholder="Event Manager"
          onChange={(e: any) => {
            localStorage.setItem('quote_event_manager', e.target.value);

            handleUpdateFilter(
              { ...filters, type: activePanel },
              setFilters,
              'event_manager',
              e.target.value,
              searchFilter
            );
          }}
        />
      ),
      dataIndex: 'event_manager',
      width: '20%',
      render: (data: any) => {
        return data ? data : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '180px' }} gap={15}>
          <Flex justifyContent="space-between">
            Total Amount
            {filters?.total_amount ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ padding: '0px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'total_amount',
                    undefined,
                    searchFilter
                  )
                }
              />
            ) : null}
          </Flex>
          <SelectInput
            id={`total_amount`}
            name={`total_amount`}
            placeholdertitle="Total Amount"
            value={filters?.total_amount}
            style={{ paddingTop: '1px' }}
            error={''}
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'total_amount',
                e,
                updateTableData
              )
            }
            allowClear={true}>
            {getSelectOptions(totalAmountOptions, 'name')}
          </SelectInput>
        </Flex>
      ),
      dataIndex: 'total_amount',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? formatCurrency(data) : '-';
      }
    },
    {
      title: (
        <Flex direction="column" style={{ width: '300px' }} gap={15}>
          <Flex justifyContent="space-between">
            Net Balance
            {filters?.net_balance ? (
              <Button
                text="Clear"
                variant="ghost"
                style={{ paddingTop: '1px' }}
                onClick={() =>
                  handleUpdateFilter(
                    { ...filters, type: activePanel },
                    setFilters,
                    'net_balance',
                    undefined,
                    searchFilter
                  )
                }
              />
            ) : null}
          </Flex>
          <SelectInput
            id={`net_balance`}
            name={`net_balance`}
            placeholdertitle="Net Balance"
            value={filters?.net_balance}
            error={''}
            onChange={(e: any) =>
              handleUpdateFilter(
                { ...filters, type: activePanel },
                setFilters,
                'net_balance',
                e,
                updateTableData
              )
            }
            allowClear={true}>
            {getSelectOptions(totalAmountOptions, 'name')}
          </SelectInput>
        </Flex>
      ),
      dataIndex: 'net_balance',
      width: '20%',
      render: (data: any, row: any) => {
        return data ? data : '-';
      }
    }
  ];
};

const getStatusTag = (data: any) => {
  return (
    <Flex justifyContent="space-between" gap={10}>
      <>{formatCurrency(data?.net_balance)}</>
      <span style={{ color: STATUS_NAME_COLORS[data?.status], textAlign: 'right' }}>
        {data?.status_info?.status_name?.toUpperCase() || '-'}
      </span>
    </Flex>
  );
};

export const mapTableData = (data: any) => {
  return data?.map((i: any) => ({
    id: i?.id,
    quotation_no: i?.quotation_no,
    event_date: i?.event_dates?.event_date,
    customer_name: i?.customer?.customer_name,
    event_name: i?.event_name,
    guests: i?.total_guest,
    venue_name: i?.venue_details?.venue_name,
    event_time:
      i?.event_dates?.start_string &&
      i?.event_dates?.end_string &&
      `${i?.event_dates?.start_string} - ${i?.event_dates?.end_string}`,
    account_manager: i?.account_manager?.name,
    kitchen_staff: i?.kitchen_staffs,
    event_staffs: i?.event_staffs,
    event_manager: i?.event_managers,
    total_amount: i?.subtotal,
    net_balance: getStatusTag(i)
  }));
};

export const getList = async (filters: any) => {
  const payloadFilters = {
    ...filters,
    page: undefined,
    status: undefined,
    type: undefined
  };
  try {
    const res = (await services.post(`/quotes/list/${filters?.status}?page=${filters?.page || 1}`, {
      ...payloadFilters
    })) as {
      data: any;
    };
    return res.data;
  } catch (err: any) {
    return { error: err && err?.error ? err?.error?.error : 'Something went wrong!' };
  }
};
