import { EVENT_WITH_EXISTING_QUOTE_TAB_KEYS } from '@pages/EventManagementPage/EventWithExistingQuote/tabHelpers';
import EventBasicDetails from '@pages/QuoteManagementPage/AddEditQuote/EventBasicDetails';
import { showErrorToast } from '@utils/index';
import { Spin } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import AddEditEventFooter from '../../AddEditEventFooter';
import { CREATE_EVENT_TAB_KEYS } from '../../helper';
import { addEditNotes, getNotes, getNotesFormInitialValues, validationSchema } from './helper';
import ManagerNotes from './ManagerNotes';
import ImportantNotes from './ImportantNotes';

const Notes = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { quoteId, eventId, formType, form } = params;
  const { withQuote } = props;
  const [formikData, setFormikData] = useState({});
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState(false);
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const formik = useFormik({
    initialValues: formikData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      submitHandler(values);
    },
    enableReinitialize: true
  });

  const { setValues, handleSubmit } = formik;

  const submitHandler = async (values: any) => {
    try {
      setSubmitting(true);
      const res = (await addEditNotes({
        ...values,
        event_id: eventId,
        event_date: details?.event_date
      })) as any;
      if (res?.success) {
        const tabBaseUrl = withQuote
          ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.TASKS}`
          : `/events/create/${CREATE_EVENT_TAB_KEYS.TASKS}/${quoteId}/${eventId}`;
        navigate(tabBaseUrl, {
          state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
        });
      } else {
        showErrorToast({
          message: 'Failed!',
          description: 'Something went wrong'
        });
      }
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
      showErrorToast({
        message: 'Failed!',
        description: 'Something went wrong'
      });
    }
  };

  const backHandler = () => {
    const tabBaseUrl = withQuote
      ? `/events/find-quote/create/${quoteId}/${eventId}/${EVENT_WITH_EXISTING_QUOTE_TAB_KEYS.VENDORS}`
      : `/events/create/${CREATE_EVENT_TAB_KEYS.SERVICE}/${quoteId}/${eventId}`;
    navigate(tabBaseUrl, {
      state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
    });
  };
  const cancelHandler = () => {
    navigate('/events');
  };

  const nextHandler = () => {
    handleSubmit();
  };

  const setupFormikInitialValues = async () => {
    if (details?.event_date) {
      try {
        const res = (await getNotes(eventId, details?.event_date)) as any;
        if (res?.success) {
          const eventData = getNotesFormInitialValues(res?.data);
          setValues(eventData);
          setFormikData(eventData);
        } else {
          showErrorToast({
            message: 'Failed!',
            description: 'Failed to fetch events data.'
          });
        }
      } catch (error: any) {
        showErrorToast({
          message: 'Failed!',
          description: 'Failed to fetch events data.'
        });
      }
    } else {
      setValues(getNotesFormInitialValues(details));
    }
    setLoading(false);
  };

  useEffect(() => {
    setupFormikInitialValues();
  }, [details?.event_id]);

  return (
    <>
      <EventBasicDetails />

      {loading ? (
        <Spin />
      ) : (
        <>
          <ImportantNotes formik={formik} />
          <ManagerNotes formik={formik} />
          <AddEditEventFooter
            showBackButton={true}
            backClickHandler={backHandler}
            cancelClickHandler={cancelHandler}
            nextClickHandler={nextHandler}
            isNextButtonDisabled={!formik?.isValid}
            isSubmitting={submitting}
          />
        </>
      )}
    </>
  );
};

export default Notes;
