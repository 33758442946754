import { Label } from '@atom/FormLable';
import SelectInput from '@atom/Select';
import { TextAreaInput } from '@atom/TextArea';
import { H2Typography } from '@constant/typography/Typography';
import { getSelectOptions } from '@utils/helper';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

interface ImportantNotesProps {
  formik: any;
}

const ImportantNotes = (props: ImportantNotesProps) => {
  const { formik } = props;
  const details = useSelector((state: any) => state.rawData?.eventDetails);
  const { touched, errors, values, handleBlur, handleChange } = formik;
  return (
    <>
      <H2Typography style={{ marginTop: '32px' }}>Important Notes</H2Typography>
      <hr />
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col md={12} xs={24}>
          <Label text="Date" isMandatory={false} />
          <SelectInput
            id={`event_date`}
            name={`event_date`}
            placeholdertitle="Date"
            value={0}
            error={touched?.event_date && errors?.event_date}
            onChange={() => {}}
            onBlur={handleBlur}
            allowClear={false}>
            {getSelectOptions(
              [
                {
                  id: 0,
                  name: details?.event_date
                    ? moment(details?.event_date).format('ddd, MMMM DD')
                    : 'Unassigned Date'
                }
              ],
              'name'
            )}
          </SelectInput>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '32px' }}>
        <Col md={24} xs={24}>
          <Label text="Important Notes" isMandatory={false} />
          <TextAreaInput
            id={'important_notes'}
            name={'important_notes'}
            placeholder={'Add Important Notes'}
            value={values?.important_notes}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched?.important_notes && errors?.important_notes}
            showCount
            maxLength={3000}
          />
        </Col>
      </Row>
    </>
  );
};

export default ImportantNotes;
