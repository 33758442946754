import React, { useState, useEffect, useRef } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { Col, Row, Select } from 'antd';

import { H4Typography } from '@constant/typography/Typography';
import { Input } from '@atom/Input';
import SelectInput from '@atom/Select';
import { Button } from '@atom/Buttons';

import { ProductsListContainer, SpecialInstructionWrapper } from './style';
import 'antd/dist/antd.css';
import MinusCircleBlack from '@assets/minus-circle-black.png';
import { FormikValues } from 'formik';
import { TypeForObjectOfProductTypes } from '@pages/QuoteManagementPage/types';
import { GetProductsList } from '@services/quoteServices';
import { Label, LabelText } from '@atom/FormLable';
import { useSelector } from 'react-redux';
import { showErrorToast, updateRawData } from '@utils/index';
import { costCalculator, duplicateProductErrorMessage } from '../helper';
import AddNewProductModal from '../AddNewProductModal';
import { isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { DEVICE } from '@constants/index';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';
import { formatCurrency } from '@utils/format';

interface ProductsVirtualListPropsType {
  formik: FormikValues;
  isEditable?: boolean;
  isBundleProductList?: boolean;
  isProductList?: boolean;
  mealIndex: number;
  bundleIndex?: number;
  isBordered?: boolean;
  availableProductTypes: TypeForObjectOfProductTypes[];
  duplicateError: any;
  setDuplicateError: (value: any) => void;
  setBundleSRPHandler?: (values: any, mealIndex: number, bundleIndex: number) => void;
  setMealSubtotalHandler?: (values: any, mIndex: number) => void;
}

const productColorList = {
  1: '#2f54eb',
  2: '#faad14',
  3: '#52c41a',
  4: '#eb2f96'
};

const getProductColor = (productType?: number) => {
  if (!productType) return null;
  else if (productType === 1) return productColorList[1];
  else if (productType === 2) return productColorList[2];
  else if (productType === 3) return productColorList[3];
  else if (productType === 4) return productColorList[4];
  else return null;
};

const RowItem = (obj: any) => {
  const { index, style } = obj;
  const {
    setValues,
    setFieldValue,
    touched,
    errors,
    values,
    isBundleProductList,
    isProductList,
    mealIndex,
    bundleIndex,
    isEditable,
    handleBlur,
    availableProductTypes,
    setBundleSRPHandler,
    setMealSubtotalHandler,
    duplicateError,
    setDuplicateError,
    quoteId,
    setIsSelectBoxFocused
  } = obj.data;

  const {
    productsList: productsListInRedux,
    showSpecialInstructionsBlockForProducts: showSpecialInstructionsBlockForProductsInRedux
  } = useSelector((state: any) => state.rawData);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [localProductType, setLocalProductType] = useState<string | number>(4);
  const [localProductSubcategoryId, setLocalProductSubcategoryId] = useState<string | number>(4);
  const [localProductSpecialInstructions, setLocalProductSpecialInstructions] =
    useState<string>('');
  const [localProductPortions, setLocalProductPortions] = useState<string>('');
  const [localProductQty, setLocalProductQty] = useState<string>('');
  const [localProductRate, setLocalProductRate] = useState<string>('');
  const [localProductTotalCost, setLocalProductTotalCost] = useState<string>('');
  const [availableProducts, setAvailableProducts] = useState<any>([]);

  const [isItemProductBoxFocused, setIsItemProductBoxFocused] = useState<boolean>(false);

  const [showSpecialInstructionsBlockForProducts, setShowSpecialInstructionsBlockForProducts] =
    useState<boolean>(false);

  const isBundle = isBundleProductList && (!!bundleIndex || bundleIndex === 0);

  const idBase: string = isBundle
    ? `event_dates.meal_plans[${mealIndex}].bundles[${bundleIndex}].products[${index}]`
    : isProductList
    ? `event_dates.meal_plans[${mealIndex}].products[${index}]`
    : '';

  const getBaseObjectForEntity = (baseObject: any) => {
    return isBundleProductList && (!!bundleIndex || bundleIndex === 0)
      ? baseObject?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex]?.products?.[index]
      : isProductList
      ? baseObject?.event_dates?.meal_plans?.[mealIndex]?.products?.[index]
      : {};
  };

  const valVarBase: any = getBaseObjectForEntity(values);
  const touchedVarBase: any = getBaseObjectForEntity(touched);
  const errorVarBase: any = getBaseObjectForEntity(errors);

  useEffect(() => {
    getProductsListHandler(localProductType);
  }, [localProductType]);

  const checkDuplicate = (values: any) => {
    setDuplicateError({});
    let productsData = [];
    let newErrors: any = { ...duplicateError };
    if (isBundle) {
      productsData = values?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex]?.products;
    } else if (isProductList) {
      productsData = values?.event_dates?.meal_plans?.[mealIndex]?.products;
    }
    for (let i = 0; i < productsData?.length - 1; i++) {
      let foundDuplicate = false;
      for (let j = i + 1; j < productsData?.length; j++) {
        foundDuplicate =
          productsData?.[i]?.product_id &&
          productsData?.[i]?.product_subcategory_id === productsData?.[j]?.product_subcategory_id &&
          productsData?.[i]?.product_id === productsData?.[j]?.product_id;
        // && Number(productsData?.[i]?.quantity) === Number(productsData?.[j]?.quantity);
        if (foundDuplicate) {
          newErrors[isBundle ? `error-${bundleIndex}-${j}` : `error-${j}`] = {
            error: duplicateProductErrorMessage,
            index: i
          };
        } else if (
          newErrors?.[isBundle ? `error-${bundleIndex}-${j}` : `error-${j}`] &&
          newErrors?.[isBundle ? `error-${bundleIndex}-${j}` : `error-${j}`]?.index === i
        ) {
          delete newErrors[isBundle ? `error-${bundleIndex}-${j}` : `error-${j}`];
        }
      }
    }
    setDuplicateError(newErrors);
  };

  const addNewProductHandler = async (sub_categories_id: any, product: any) => {
    setNewProductHandler(product, sub_categories_id);
  };

  const setNewProductHandler = (product: any, sub_categories_id: any) => {
    let subcategory = product?.sub_categories[0];
    let price = 0;
    sub_categories_id
      ? product?.sub_categories?.forEach((i: any) => (price += Number(i?.price)))
      : (price = subcategory?.price);
    const productToSet = {
      id: subcategory?.id,
      product_id: subcategory?.product_id,
      product_subcategory_id: subcategory?.id,
      product_name: product?.product_name,
      product_type: product?.product_type,
      special_instruction: '',
      portions: '',
      quantity: '',
      rate: price,
      price: price,
      disabled: false,
      master_category: product?.category?.name,
      subcategory: subcategory?.category?.name,
      product: {
        id: subcategory?.id,
        is_one_time_product: product?.is_one_time_product,
        product_name: product?.product_name,
        product_type: product?.product_type
      },
      is_one_time_product: product?.is_one_time_product,
      status: product?.status
    };
    const products = [...availableProducts];
    if (!isEmpty(availableProducts)) {
      products.push(productToSet);
      setAvailableProducts(products);
      updateRawData({
        productsList: productsListInRedux?.length
          ? [...productsListInRedux, productToSet]
          : products
      });
      sub_categories_id && changeProductHandler(sub_categories_id, products);
    } else {
      const newProd = [productToSet];
      setAvailableProducts(newProd);
      updateRawData({
        productsList: productsListInRedux?.length ? [...productsListInRedux, ...newProd] : newProd
      });
      sub_categories_id && changeProductHandler(sub_categories_id, newProd);
    }
    if (sub_categories_id) {
      setFieldValue(`${idBase}.product_type`, product?.product_type);
      setLocalProductType(product?.product_type);
    }
  };
  const getProductsListHandler = async (prodType?: string | number) => {
    if (prodType) {
      if (productsListInRedux) {
        setAvailableProducts(
          productsListInRedux?.filter?.((product: any) => product?.product_type === prodType)
        );
      } else {
        const res: any = await GetProductsList('', quoteId);
        if (res?.success) {
          updateRawData({ productsList: res?.data });
          setAvailableProducts(
            res?.data?.filter?.((product: any) => product?.product_type === prodType)
          );
        }
      }
    }
  };

  const deleteProductHandler = () => {
    let newErrors: any = { ...duplicateError };
    delete newErrors[isBundle ? `error-${bundleIndex}-${index}` : `error-${index}`];
    setDuplicateError(newErrors);
    const idBaseForDelete: string =
      isBundleProductList && (!!bundleIndex || bundleIndex === 0)
        ? `event_dates.meal_plans[${mealIndex}].bundles[${bundleIndex}].products`
        : isProductList
        ? `event_dates.meal_plans[${mealIndex}].products`
        : '';
    const valBaseObjectForDelete: any =
      isBundleProductList && (!!bundleIndex || bundleIndex === 0)
        ? values?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex]?.products
        : isProductList
        ? values?.event_dates?.meal_plans?.[mealIndex]?.products
        : [];
    const tempValObj = [...JSON.parse(JSON.stringify(valBaseObjectForDelete))];
    tempValObj.splice(index, 1);
    setFieldValue(idBaseForDelete, tempValObj);
  };

  useEffect(() => {
    setLocalProductType(valVarBase?.product_type);
    setLocalProductSubcategoryId(valVarBase?.product_subcategory_id);
    setLocalProductSpecialInstructions(valVarBase?.special_instruction);
    setLocalProductPortions(valVarBase?.portions);
    setLocalProductQty(valVarBase?.quantity);
    setLocalProductRate(valVarBase?.rate);
    setLocalProductTotalCost(valVarBase?.total_cost);
    setShowSpecialInstructionsBlockForProducts(
      isProductList && valVarBase?.special_instruction
        ? true
        : !!showSpecialInstructionsBlockForProductsInRedux?.[mealIndex]?.[index]
    );
    !productsListInRedux && getProductsListHandler(valVarBase?.product_type);
    calculationHandler(valVarBase?.quantity, valVarBase?.rate);
  }, []);

  const calculationHandler = (qty: string | number, rate: string | number) => {
    const totalCost = costCalculator(qty, rate).toString();
    setLocalProductTotalCost(totalCost);

    const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
    const baseObject = getBaseObjectForEntity(tempValues);
    if (baseObject) {
      baseObject['quantity'] = qty;
      baseObject['rate'] = rate;
      baseObject['total_cost'] = totalCost;
    }
    setValues(tempValues);
    if (isBundleProductList) {
      setBundleSRPHandler && setBundleSRPHandler(tempValues, mealIndex, bundleIndex);
    } else if (isProductList) {
      setMealSubtotalHandler && setMealSubtotalHandler(tempValues, mealIndex);
    }
    checkDuplicate(tempValues);
  };

  const changeProductHandler = (prodSubcatId: string | number, newProducts?: any) => {
    setLocalProductSubcategoryId((prev) => {
      if (prev !== prodSubcatId) {
        const products = newProducts || availableProducts;
        setLocalProductSpecialInstructions('');
        const tempProd = products?.find(
          (item: any) => item?.product_subcategory_id === prodSubcatId
        );
        setLocalProductPortions('');
        setLocalProductQty('1');
        setLocalProductRate(tempProd?.price);
        setLocalProductTotalCost(tempProd?.price);

        const tempValues = { ...JSON.parse(JSON.stringify({ ...values })) };
        const baseObject = getBaseObjectForEntity(tempValues);
        baseObject['product_id'] = tempProd?.product_id;
        baseObject['product_name'] = tempProd?.product_name;
        baseObject['quantity'] = '1';
        baseObject['rate'] = tempProd?.price;
        baseObject['total_cost'] = tempProd?.price;
        baseObject['product_subcategory_id'] = prodSubcatId;
        baseObject['product_type'] = tempProd?.product_type;
        baseObject['product'] = {};
        baseObject['product']['id'] = tempProd?.product_id;
        baseObject['product']['is_one_time_product'] = tempProd?.is_one_time_product;
        baseObject['product']['product_name'] = tempProd?.product_name;
        baseObject['product']['product_type'] = tempProd?.product_type;
        baseObject['product']['status'] = tempProd?.status;

        if (isBundleProductList) {
          setBundleSRPHandler && setBundleSRPHandler(tempValues, mealIndex, bundleIndex);
        } else if (isProductList) {
          setMealSubtotalHandler && setMealSubtotalHandler(tempValues, mealIndex);
        }
        checkDuplicate(tempValues);
        setTimeout(() => {
          setValues(tempValues);
        }, 200);
      }
      return prodSubcatId;
    });
  };

  const getIngredients = (productId: any, availableProducts: any) => {
    const matchedProduct = availableProducts.find(
      (product: any) => product.product_id === productId
    );
    return matchedProduct && matchedProduct.ingredients && matchedProduct.ingredients.length > 0
      ? matchedProduct.ingredients.map((ingredient: any) => ingredient.name).join(', ')
      : 'No ingredients added';
  };

  return (
    <Row
      gutter={[10, 10]}
      key={`mealPlan-${mealIndex}${
        isBundleProductList ? 'bundle-' + bundleIndex + '-product' : 'meal-product'
      }-${index}-${valVarBase?.product_subcategory_id}`}
      style={{ ...style, cursor: 'pointer' }}
    >
      <Col
        lg={isBundleProductList ? 4 : 6}
        md={isBundleProductList ? 4 : 6}
        xs={isBundleProductList ? 4 : 24}
      >
        {!isEditable ? (
          <div style={{ padding: '16px 0px', textAlign: 'left' }}>
            {availableProductTypes?.find(
              (item: TypeForObjectOfProductTypes) => item?.id === localProductType
            )?.text || ''}
          </div>
        ) : (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Product Type'} />}
            <SelectInput
              id={`${idBase}.product_type`}
              name={`${idBase}.product_type`}
              allowClear={true}
              placeholdertitle="Select from the list"
              onChange={(value: any) => {
                // value.account_manager = value;
                //   setAuthor(value.event_date);
                setLocalProductType((prev: string | number) => {
                  if (prev !== value) {
                    setLocalProductSubcategoryId('');
                    getProductsListHandler(value);
                    setTimeout(() => {
                      setFieldValue(`${idBase}.product_type`, value);
                    }, 200);
                  }
                  return value;
                });
              }}
              onBlur={handleBlur}
              error={touchedVarBase?.product_type && errorVarBase?.product_type}
              // value={valVarBase?.product_type || ''}
              value={localProductType || []}
            >
              {availableProductTypes?.map((item: TypeForObjectOfProductTypes, index: number) => (
                <Select.Option
                  style={{ whiteSpace: 'inital' }}
                  key={`availableProductTypes-${index}-${item?.id}`}
                  value={item?.id}
                >
                  {item?.text}
                </Select.Option>
              ))}
            </SelectInput>
          </>
        )}
      </Col>

      <Col
        lg={isBundleProductList ? 4 : 6}
        md={isBundleProductList ? 4 : 6}
        xs={isBundleProductList ? 4 : 24}
      >
        {!isEditable ? (
          <div style={{ padding: '16px 0px', textAlign: 'left' }}>
            {' '}
            <Tooltip
              placement="bottom"
              title={getIngredients(valVarBase.product_id, availableProducts)}
            >
              {valVarBase?.product_name}
            </Tooltip>
          </div>
        ) : (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Products'} />}
            <SelectInput
              id={`${idBase}.product_subcategory_id`}
              name={`${idBase}.product_subcategory_id`}
              className="bottom-0"
              allowClear={true}
              placeholdertitle="Select from the list"
              onChange={(value: any) => {
                setIsItemProductBoxFocused(false);
                changeProductHandler(value);
              }}
              onFocus={(e: any) => {
                setIsItemProductBoxFocused(true);
                setIsSelectBoxFocused(true);
              }}
              onBlur={(e: any) => {
                setIsItemProductBoxFocused(false);
                setIsSelectBoxFocused(false);
                setTimeout(() => {
                  handleBlur(e);
                }, 200);
              }}
              listHeight={165}
              error={
                errorVarBase?.product_subcategory_id ||
                duplicateError?.[isBundle ? `error-${bundleIndex}-${index}` : `error-${index}`]
                  ?.error
              }
              // value={valVarBase?.product_subcategory_id || ''}
              value={(valVarBase?.product?.status && localProductSubcategoryId) || []}
              filterOption={(key: any, e: any) =>
                e?.children?.props?.children?.[0]?.props?.children
                  ?.toLowerCase()
                  ?.includes(key?.toLowerCase())
              }
              // disabled={availableProducts?.length}
            >
              <Select.Option
                style={{ whiteSpace: 'inital' }}
                key={`productListOption--1`}
                value={-1}
                disabled={true}
              >
                <Button
                  style={{ padding: 0, width: '100%' }}
                  text={'+ Add New Product'}
                  variant="ghost"
                  onClick={() => setIsModalVisible(true)}
                />
              </Select.Option>
              {availableProducts?.map((item: any, index: number) => (
                <Select.Option
                  style={{ whiteSpace: 'inital' }}
                  key={`productListOption-${index}}${item?.product_subcategory_id}`}
                  value={item?.product_subcategory_id}
                  title={
                    item?.ingredients?.length > 0
                      ? item.ingredients.map((ingredient: any) => ingredient.name).join(', ')
                      : 'No ingredients added'
                  }
                >
                  {localProductSubcategoryId === item?.product_subcategory_id &&
                  !isItemProductBoxFocused ? (
                    item?.product_name
                  ) : (
                    <>
                      <span style={{ color: `${getProductColor(item?.product_type)}` }}>
                        {item?.product_name}
                      </span>
                      <span style={{ color: item?.product_type < 5 ? 'red' : 'black' }}>
                        {` 
                    - ${item?.master_category} / ${item?.subcategory}`}{' '}
                      </span>
                    </>
                  )}
                </Select.Option>
              ))}
            </SelectInput>
            {isProductList && (
              <>
                {!showSpecialInstructionsBlockForProducts ? (
                  <span
                    style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'right' }}
                    onClick={() => {
                      setShowSpecialInstructionsBlockForProducts(true);
                      let rawData: any = !!showSpecialInstructionsBlockForProductsInRedux
                        ? showSpecialInstructionsBlockForProductsInRedux
                        : {};
                      rawData[mealIndex] = !!showSpecialInstructionsBlockForProductsInRedux?.[
                        mealIndex
                      ]
                        ? showSpecialInstructionsBlockForProductsInRedux?.[mealIndex]
                        : {};
                      rawData[mealIndex][index] = true;
                      updateRawData({ showSpecialInstructionsBlockForProducts: rawData });
                    }}
                  >
                    + Add Special Instructions
                  </span>
                ) : (
                  <>
                    <LabelText style={{ fontSize: '18px' }}>Special Instructions</LabelText>
                    <Input
                      id={`${idBase}.special_instruction`}
                      name={`${idBase}.special_instruction`}
                      value={localProductSpecialInstructions}
                      placeholder="Add Instructions"
                      onChange={(e: any) => {
                        setLocalProductSpecialInstructions(e?.target?.value);
                        setTimeout(() => {
                          setFieldValue(`${idBase}.special_instruction`, e?.target?.value);
                        }, 200);
                      }}
                      onBlur={handleBlur}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Col>
      {isBundleProductList && (
        <Col
          lg={isBundleProductList ? 4 : 3}
          md={isBundleProductList ? 4 : 3}
          xs={isBundleProductList ? 4 : 24}
        >
          {!isEditable ? (
            <SpecialInstructionWrapper style={{ textAlign: 'left', padding: '16px 10px' }}>
              <Tooltip placement="top" title={valVarBase?.special_instruction}>
                {valVarBase?.special_instruction?.length > 70
                  ? `${valVarBase?.special_instruction?.substring(0, 70)}...`
                  : valVarBase?.special_instruction}
              </Tooltip>
            </SpecialInstructionWrapper>
          ) : (
            <Input
              id={`${idBase}.special_instruction`}
              name={`${idBase}.special_instruction`}
              // value={valVarBase?.special_instruction}
              // onChange={handleChange}
              value={localProductSpecialInstructions}
              onChange={(e: any) => {
                setLocalProductSpecialInstructions(e?.target?.value);
                setTimeout(() => {
                  setFieldValue(`${idBase}.special_instruction`, e?.target?.value);
                }, 200);
              }}
              onBlur={handleBlur}
            />
          )}
        </Col>
      )}
      <Col lg={3} md={3} xs={isBundleProductList ? 3 : 12}>
        {!isEditable ? (
          <div style={{ padding: '16px', textAlign: 'left' }}>{valVarBase?.portions}</div>
        ) : (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Portion'} />}
            <Input
              id={`${idBase}.portions`}
              name={`${idBase}.portions`}
              // value={valVarBase?.portions}
              // onChange={handleChange}
              value={localProductPortions}
              onChange={(e: any) => {
                const valueToUpdate: string = e?.target?.value?.replace(/\D/g, '');
                setLocalProductPortions(valueToUpdate);
                setTimeout(() => {
                  setFieldValue(`${idBase}.portions`, valueToUpdate);
                }, 200);
              }}
              onBlur={handleBlur}
            />
          </>
        )}
      </Col>
      <Col
        lg={isBundleProductList ? 2 : 3}
        md={isBundleProductList ? 2 : 3}
        xs={isBundleProductList ? 2 : 12}
      >
        {!isEditable ? (
          <div style={{ padding: '16px', textAlign: 'left' }}>{valVarBase?.quantity}</div>
        ) : (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Qunatity'} />}
            <Input
              id={`${idBase}.quantity`}
              placeholder="0"
              name={`${idBase}.quantity`}
              // value={valVarBase?.quantity}
              // onChange={handleChange}
              type="number"
              value={localProductQty}
              onChange={(e: any) => {
                setLocalProductQty(e?.target?.value);
                calculationHandler(e?.target?.value, localProductRate);
                setTimeout(() => {
                  setFieldValue(`${idBase}.quantity`, e?.target?.value);
                }, 200);
              }}
              onBlur={handleBlur}
            />
          </>
        )}
      </Col>
      <Col lg={3} md={3} xs={isBundleProductList ? 3 : 12}>
        {isEditable || isProductList ? (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Rate'} />}
            <Input
              id={`${idBase}.rate`}
              placeholder="0"
              prefix="$"
              name={`${idBase}.rate`}
              // value={valVarBase?.rate}
              // onChange={handleChange}
              value={localProductRate}
              type="number"
              onChange={(e: any) => {
                setLocalProductRate(e?.target?.value);
                calculationHandler(localProductQty, e?.target?.value);
                setTimeout(() => {
                  setFieldValue(`${idBase}.rate`, e?.target?.value);
                }, 200);
              }}
            />
          </>
        ) : (
          <div style={{ padding: '16px', textAlign: 'left' }}>
            {formatCurrency(valVarBase?.rate)}
          </div>
        )}
      </Col>
      <Col
        lg={isBundleProductList ? 3 : 2}
        md={isBundleProductList ? 3 : 2}
        xs={isBundleProductList ? 3 : 10}
      >
        {isProductList ? (
          <>
            {!isBundleProductList && <Label className="hide-full-device" text={'Total Cost'} />}
            <div style={{ padding: '16px' }}>{formatCurrency(Number(localProductTotalCost))}</div>
          </>
        ) : (
          <>
            <Input
              placeholder="0"
              id={`${idBase}.total_cost`}
              name={`${idBase}.total_cost`}
              // value={valVarBase?.rate}
              // onChange={handleChange}
              //@ts-ignore
              value={formatCurrency(Number(localProductTotalCost))}
              onChange={(e: any) => {
                setLocalProductTotalCost(e?.target?.value);
                setTimeout(() => {
                  setFieldValue(
                    `${idBase}.total_cost`,
                    parseFloat(localProductTotalCost).toFixed(2).toString()
                  );
                }, 200);
              }}
              disabled={true}
            />
          </>
        )}
      </Col>
      {isEditable && (
        <Col lg={1} md={1} xs={1}>
          <Button
            style={{ marginTop: '15px' }}
            icon={<img src={MinusCircleBlack} alt="delete" />}
            variant="ghost"
            onClick={deleteProductHandler}
          />
        </Col>
      )}
      {isModalVisible && (
        <AddNewProductModal
          mealIndex={mealIndex}
          isModalVisible={isModalVisible}
          addNewProductHandler={addNewProductHandler}
          onOk={() => {
            setIsModalVisible(false);
          }}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Row>
  );
};

const ProductsVirtualList = (props: ProductsVirtualListPropsType) => {
  const params = useParams();
  const { quoteId } = params;
  const {
    formik,
    isBundleProductList,
    isProductList,
    mealIndex,
    bundleIndex,
    isEditable,
    isBordered = true,
    availableProductTypes,
    duplicateError,
    setDuplicateError,
    setBundleSRPHandler,
    setMealSubtotalHandler
  } = props;
  const { handleChange, setFieldValue, setValues, values, handleBlur, touched, errors } = formik;
  const [data, setData] = useState<any[]>([]);
  const [isSelectBoxFocused, setIsSelectBoxFocused] = useState<boolean>(false);

  const listRef = useRef<List>(null);

  const device = useWindowSizeChangedforGrid();

  const itemSize =
    device === DEVICE.MOBILE && isProductList
      ? 520
      : (device === DEVICE.DESKTOP || device === DEVICE.TAB) && isProductList
      ? 170
      : 85;

  useEffect(() => {
    const data =
      isBundleProductList && (!!bundleIndex || bundleIndex === 0)
        ? JSON.parse(
            JSON.stringify(
              values?.event_dates?.meal_plans?.[mealIndex]?.bundles?.[bundleIndex]?.products || []
            )
          ) || []
        : isProductList
        ? JSON.parse(
            JSON.stringify(values?.event_dates?.meal_plans?.[mealIndex]?.products || [])
          ) || []
        : [];
    setData(data);
  }, [
    values?.event_dates?.meal_plans?.[mealIndex]?.bundles,
    values?.event_dates?.meal_plans?.[mealIndex]?.products
  ]);

  useEffect(() => {
    if (!listRef?.current) return;
    listRef?.current?.scrollToItem(data.length);
  }, [data.length]);

  return (
    <>
      <ProductsListContainer
        isBordered={isBordered}
        dataLength={data.length}
        isProductList={isProductList}
        isSelectBoxFocused={isSelectBoxFocused}
      >
        <Row
          gutter={10}
          className={`product-list-header ${!isBundleProductList ? 'hide-mobile-device' : ''}`}
        >
          <Col span={isProductList ? 6 : 4} style={{ paddingRight: 0 }}>
            <H4Typography>{isProductList ? 'Product Type' : 'PRODUCT TYPE'}</H4Typography>
          </Col>

          <Col span={isProductList ? 6 : 4} style={{ padding: 0 }}>
            <H4Typography>{isProductList ? 'Product' : 'PRODUCT'}</H4Typography>
          </Col>
          {isBundleProductList && (
            <Col span={isProductList ? 6 : 4} style={{ padding: 0 }}>
              <H4Typography>SPECIAL INSTRUCTIONS</H4Typography>
            </Col>
          )}
          <Col span={3} style={{ padding: 0 }}>
            <H4Typography>{isProductList ? 'Portions' : 'PORTIONS'}</H4Typography>
          </Col>
          <Col span={isProductList ? 3 : 2}>
            <H4Typography>{isProductList ? 'Qty' : 'QTY'}</H4Typography>
          </Col>
          <Col span={3} style={{ padding: 0 }}>
            <H4Typography>{isProductList ? 'Rate' : 'RATE'}</H4Typography>
          </Col>
          <Col span={2} style={{ padding: 0 }}>
            <H4Typography>{isProductList ? 'Total Cost' : 'TOTAL'}</H4Typography>
          </Col>
          <Col span={isProductList ? 1 : ''}></Col>
        </Row>

        <AutoSizer>
          {({ height, width }: { height: any; width: any }) => {
            return (
              <List
                ref={listRef}
                className="List"
                height={height ? height - 50 : 330}
                itemCount={data.length}
                itemSize={itemSize} //@ts-ignore
                width={width}
                key={data.length}
                itemData={{
                  handleChange,
                  setFieldValue,
                  setValues,
                  touched,
                  errors,
                  values,
                  handleBlur,
                  isBundleProductList,
                  isProductList,
                  mealIndex,
                  bundleIndex,
                  isEditable,
                  availableProductTypes,
                  duplicateError,
                  setDuplicateError,
                  setBundleSRPHandler,
                  setMealSubtotalHandler,
                  quoteId,
                  setIsSelectBoxFocused
                }}
              >
                {RowItem}
              </List>
            );
          }}
        </AutoSizer>
      </ProductsListContainer>
    </>
  );
};
export default ProductsVirtualList;
