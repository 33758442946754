import FormDataView, { Value } from '@atom/FormDataView';
import { H2Typography, H4Typography } from '@constant/typography/Typography';
import { Col, Row } from 'antd';
import {
  EventContentWrapper,
  ManagerNotesDiv,
  PointofContactWrapper,
  SectionTitleDiv
} from './style';
import { useWindowSizeChangedforGrid } from '@hooks/useWindowSizeChangedforGrid';

interface VenueInformationProps {
  data: any;
  isEvent?: boolean;
  isQuoteTab?: boolean;
}

const VenueInformation = (props: VenueInformationProps) => {
  const { data, isEvent, isQuoteTab } = props;
  const device = useWindowSizeChangedforGrid();

  return (
    <>
      <Row gutter={16} className="xs-top-space" style={{ marginTop: '32px' }}>
        <Col span={24}>
          <SectionTitleDiv>
            {/* <H2Typography>Venue Information</H2Typography> */}
            <H2Typography className={isEvent ? 'event-section' : 'quote-section'}>
              {isEvent ? 'Location Information' : 'Venue Information'}
            </H2Typography>
          </SectionTitleDiv>
        </Col>
      </Row>
      {/* <Row gutter={[16, 16]}>
        <Col lg={8} xs={isQuoteTab || isEvent ? 12 : 12}>
          <FormDataView
            heading="VENUE NAME"
            value={data?.venue_details?.venue_name || data?.venue_name}
          />
        </Col>
        <Col lg={8} xs={isQuoteTab || isEvent ? 12 : 12}>
          <FormDataView
            heading="VENUE SUBSECTION"
            value={data?.venue_details?.venue_sub_section || data?.venue_sub_section}
          />
        </Col>
        <Col lg={8} xs={24}>
          <FormDataView
            heading="VENUE ADDRESS"
            value={data?.venue_details?.venue_address || data?.venue_address}
          />
        </Col>
      </Row> */}
      <EventContentWrapper style={isEvent ? { paddingLeft: '15px', paddingRight: '15px' } : {}}>
        {isEvent && (
          <Row gutter={[16, 16]} style={{ paddingTop: `${device == 'mobile' ? '0px' : '10px'}` }}>
            <Col lg={8} xs={isQuoteTab || isEvent ? 12 : 12}>
              <FormDataView
                heading="VENUE NAME"
                value={data?.venue_details?.venue_name || data?.venue_name}
              />
            </Col>
            <Col lg={8} xs={12}>
              <FormDataView
                heading="VENUE ADDRESS"
                value={
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                      data?.venue_details?.venue_address || data?.venue_address
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'black', textDecorationLine: 'underline' }}>
                    {data?.venue_details?.venue_address || data?.venue_address}
                  </a>
                }
              />
            </Col>
            <Col lg={8} xs={24}>
              <FormDataView
                heading="Staff entrance & Parking"
                value={
                  Number(data?.parking) &&
                  data?.parking_additional_information &&
                  data?.parking_additional_information
                }
                // value={
                //   <ManagerNotesDiv
                //     style={{
                //       minHeight: '100px',
                //       display: 'flex',
                //       alignItems: 'start',
                //       justifyContent: 'space-between',
                //       flexDirection: 'column'
                //     }}>
                //     {Number(data?.parking) && data?.parking_additional_information ? (
                //       data?.parking_additional_information
                //     ) : (
                //       <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>{/* Not Specified */}</span>
                //     )}
                //     <span
                //       style={{
                //         color: '#5C5C5C',
                //         fontSize: '12px',
                //         alignSelf: 'end'
                //       }}>{`${
                //       Number(data?.parking) ? data?.parking_additional_information?.length || 0 : 0
                //     }/200`}</span>
                //   </ManagerNotesDiv>
                // }
              />
            </Col>
          </Row>
        )}
        {!isEvent && (
          <Row gutter={[16, 16]}>
            <Col lg={8} xs={isQuoteTab || isEvent ? 12 : 12}>
              <FormDataView
                heading="VENUE NAME"
                value={data?.venue_details?.venue_name || data?.venue_name}
              />
            </Col>
            <Col lg={8} xs={isQuoteTab || isEvent ? 12 : 12}>
              <FormDataView
                heading="VENUE SUBSECTION"
                value={data?.venue_details?.venue_sub_section || data?.venue_sub_section}
              />
            </Col>
            <Col lg={8} xs={24}>
              <FormDataView
                heading="VENUE ADDRESS"
                value={data?.venue_details?.venue_address || data?.venue_address}
              />
            </Col>
          </Row>
        )}
        <Row gutter={16} className="xs-top-space" style={{ marginTop: '10px' }}>
          {/* {isEvent && (
          <>
            <Col lg={8} xs={12}>
              <FormDataView heading="VENUE AVAILABILITY" value={data?.venue_availability} />
            </Col>
            <Col lg={8} xs={12}>
              <FormDataView heading="VENUE CAPACITY" value={data?.capacity} />
            </Col>
            <Col lg={8} xs={24}>
              <FormDataView heading="FD ACCOUNT MANAGER" value={data?.fd_account_manager} />
            </Col>
          </>
        )} */}
          {!isEvent && (
            <Col span={24}>
              <SectionTitleDiv>
                <H4Typography className={'quote-section'} style={{ paddingBottom: '8px' }}>
                  VENUE POINT OF CONTACTS
                </H4Typography>
              </SectionTitleDiv>
            </Col>
          )}
        </Row>
        {!isEvent &&
          data?.point_of_contact_details?.map((i: any, index: number) =>
            !isQuoteTab ? (
              <PointofContactWrapper gutter={[16, 16]} key={index}>
                <Col lg={8} xs={isQuoteTab ? 24 : 8} className={'point-of-contact-col'}>
                  <FormDataView heading="CONTACT NAME" value={i?.contact_name} />
                </Col>
                <Col lg={8} xs={isQuoteTab ? 24 : 8} className={'point-of-contact-col'}>
                  <FormDataView
                    heading="CONTACT PHONE NUMBER"
                    value={
                      <a href={`tel:${i?.contact_phone_number}`} style={{ color: 'black' }}>
                        {i?.contact_phone_number}
                      </a>
                    }
                  />
                </Col>
                <Col lg={8} xs={isQuoteTab ? 24 : 8} className={'point-of-contact-col'}>
                  <FormDataView
                    heading="CONTACT EMAIL"
                    value={
                      <a href={`mailto:${i?.contact_email}`} style={{ color: 'black' }}>
                        {i?.contact_email}
                      </a>
                    }
                  />
                </Col>
              </PointofContactWrapper>
            ) : (
              <Row gutter={[16, 16]} key={index}>
                <Col lg={8} xs={24} className={'point-of-contact-col'}>
                  <FormDataView heading="CONTACT NAME" value={i?.contact_name} />
                </Col>
                <Col lg={8} xs={24} className={'point-of-contact-col'}>
                  <FormDataView
                    heading="CONTACT PHONE NUMBER"
                    value={
                      <a href={`tel:${i?.contact_phone_number}`} style={{ color: 'black' }}>
                        {i?.contact_phone_number}
                      </a>
                    }
                  />{' '}
                </Col>
                <Col lg={8} xs={24} className={'point-of-contact-col'}>
                  <FormDataView
                    heading="CONTACT EMAIL"
                    value={
                      <a href={`mailto:${i?.contact_email}`} style={{ color: 'black' }}>
                        {i?.contact_email}
                      </a>
                    }
                  />
                </Col>
              </Row>
            )
          )}
      </EventContentWrapper>
    </>
  );
};

export default VenueInformation;
