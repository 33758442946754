import CenterSpiner from '@atom/CenterSpiner';
import { Flex } from '@atom/Flex';
import { authActions } from '@store/authReducer';
import { userActions } from '@store/userProfileReducer';
import { Spin } from 'antd';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';
import { fetchUserInfo } from './helper';
import LogRocket from 'logrocket';

const RoutesComponent = () => {
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state: any) => {
    return state.auth;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (auth?.token) {
        setLoading(true);
        const res = (await fetchUserInfo()) as any;
        const user = res?.data;
        localStorage.setItem('userData', JSON.stringify(user));
        LogRocket.identify(user?.user_id, {
          name: user?.name,
          email: user?.email,
          department: user?.departments?.department?.name
        });
        if (!res.error) {
          dispatch(authActions.setAuth(res.data.token));
          dispatch(userActions.setUser(res.data));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
    setLoading(false);
  }, [auth?.token]);

  if (loading) {
    return (
      <>
        <CenterSpiner />
      </>
    );
  }

  return (
    <Suspense fallback={<Spin />}>
      <Flex style={{ height: '100%' }}>{!!auth?.token ? <AppRoutes /> : <AuthRoutes />}</Flex>
    </Suspense>
  );
};

export default RoutesComponent;
