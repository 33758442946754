import CustomerForm from '@pages/QuoteManagementPage/AddEditQuote/Customer/CustomerForm';
import DocuementsTab from '../EventWithExistingQuote/EventWithExistingQuoteTabPanels/DocuementsTab';
import FeesForm from '@pages/QuoteManagementPage/AddEditQuote/FeesTab/FeesForm';
import { getEventDetails } from '@pages/QuoteManagementPage/AddEditQuote/helper';
import LaboursForm from '@pages/QuoteManagementPage/AddEditQuote/Labours/LaboursForm';
import MealOrder from './WithoutQuoteTabs/MealOrder';
import { updateRawData } from '@utils/index';
import Notes from './SharedTabs/Notes';
import OthersEstimateForm from '@pages/QuoteManagementPage/AddEditQuote/OthersTab/OthersForm';
import Service from './SharedTabs/Service';
import StaffingInformation from './SharedTabs/Staffing';
import TasksTab from '../EventWithExistingQuote/EventWithExistingQuoteTabPanels/TasksTab';
import VendorsForm from '@pages/QuoteManagementPage/AddEditQuote/Vendors/VendorsForm';
import VenueForm from '@pages/QuoteManagementPage/AddEditQuote/Venue/VenueForm';
import services from '@services/index';
import MealPlanForm from '@pages/QuoteManagementPage/AddEditQuote/MealPlan/MealPlanForm';

export const enum CREATE_EVENT_FORM_TYPE {
  WITH_QUOTE = 'with-quote',
  WITOUT_QUOTE = 'without-quote'
}

export const enum CREATE_EVENT_TAB_KEYS {
  QUOTE = 'quote',
  CUSTOMER = 'customer',
  SERVICE = 'service',
  NOTES = 'notes',
  LABOURS = 'labour',
  FEES = 'fees',
  VENUE = 'venue',
  STAFFING = 'staffing',
  TASKS = 'tasks',
  DOCUMENTS = 'documents',
  OTHERS = 'others',
  MEALPLAN = 'meal-plan',
  MEALORDER = 'meal-order',
  VENDORS = 'vendor'
}

export const submitForm = async (tab: string, data: any, method: string, isEvent?: number) => {
  try {
    //@ts-ignore
    const res: any = await services?.[method || 'post']?.(`/events/${tab}`, {
      ...data,
      from_event: isEvent ?? 1
    });
    if (res?.data?.success) return { data: res?.data?.data, success: true };
  } catch (err: any) {
    return {
      error: err?.error?.message
        ? err?.error?.message
        : err?.error?.error
        ? err?.error?.error
        : 'Something went wrong!',
      success: false
    };
  }
};

export const getTabBaseUrl = (quoteId: any, eventId: any, nextTabKey: string) => {
  return location?.pathname.includes('edit')
    ? `/events/edit/${nextTabKey}${quoteId ? `/${quoteId}` : ''}${eventId ? `/${eventId}` : ''}`
    : `/events/create/${nextTabKey}${quoteId ? `/${quoteId}` : ''}${eventId ? `/${eventId}` : ''}`;
};

export const NEXT_TAB: any = {
  [CREATE_EVENT_TAB_KEYS.CUSTOMER]: CREATE_EVENT_TAB_KEYS.VENUE,
  [CREATE_EVENT_TAB_KEYS.VENUE]: CREATE_EVENT_TAB_KEYS.MEALPLAN,
  [CREATE_EVENT_TAB_KEYS.MEALPLAN]: CREATE_EVENT_TAB_KEYS.MEALORDER,
  [CREATE_EVENT_TAB_KEYS.MEALORDER]: CREATE_EVENT_TAB_KEYS.LABOURS,
  [CREATE_EVENT_TAB_KEYS.LABOURS]: CREATE_EVENT_TAB_KEYS.VENDORS,
  [CREATE_EVENT_TAB_KEYS.VENDORS]: CREATE_EVENT_TAB_KEYS.OTHERS,
  [CREATE_EVENT_TAB_KEYS.OTHERS]: CREATE_EVENT_TAB_KEYS.FEES,
  [CREATE_EVENT_TAB_KEYS.FEES]: CREATE_EVENT_TAB_KEYS.STAFFING,
  [CREATE_EVENT_TAB_KEYS.STAFFING]: CREATE_EVENT_TAB_KEYS.SERVICE,
  [CREATE_EVENT_TAB_KEYS.SERVICE]: CREATE_EVENT_TAB_KEYS.NOTES,
  [CREATE_EVENT_TAB_KEYS.NOTES]: CREATE_EVENT_TAB_KEYS.TASKS,
  [CREATE_EVENT_TAB_KEYS.TASKS]: CREATE_EVENT_TAB_KEYS.DOCUMENTS
};

export const getEventManagementTabs = (props: any) => {
  const { eventId, quoteId, tabKey, eventBasicDetails, submitHandler } = props;
  const isTabDisabled: boolean = !eventId;
  let TABS = [
    {
      tab: 'CUSTOMER',
      content: (
        <CustomerForm
          isEvent={true}
          tabKey={tabKey}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
        />
      ),
      disabled: false,
      key: CREATE_EVENT_TAB_KEYS.CUSTOMER
    },
    {
      tab: 'VENUE',
      content: (
        <VenueForm
          isEvent={true}
          tabKey={tabKey}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.VENUE
    },
    {
      tab: 'MEAL PLAN',
      content: (
        <MealPlanForm
          tabKey={tabKey}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
          isEventsTab={true}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.MEALPLAN
    },
    {
      tab: 'MEAL ORDER',
      content: <MealOrder tabKey={tabKey} submitForm={submitHandler} isEventsTab={true} />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.MEALORDER
    },
    {
      tab: 'LABOURS',
      content: (
        <LaboursForm
          isEventsTab={true}
          tabKey={tabKey}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.LABOURS
    },
    {
      tab: 'VENDORS',
      content: (
        <VendorsForm
          isEventsTab={true}
          tabKey={CREATE_EVENT_TAB_KEYS.VENDORS}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.VENDORS
    },
    {
      tab: 'OTHERS',
      content: (
        <OthersEstimateForm
          tabKey={tabKey}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          isEvent={true}
          submitForm={submitHandler}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.OTHERS
    },
    {
      tab: 'FEES',
      content: (
        <FeesForm
          isEventsTab={true}
          tabKey={tabKey}
          navigationUrl={getTabBaseUrl(quoteId, eventId, CREATE_EVENT_TAB_KEYS.STAFFING)}
          quoteBasicDetails={eventBasicDetails}
          setQuoteBasicDetails={() => {}}
          submitForm={submitHandler}
        />
      ),
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.FEES
    },
    {
      tab: 'STAFFING',
      content: <StaffingInformation {...props} />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.STAFFING
    },
    {
      tab: 'SERVICE',
      content: <Service />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.SERVICE
    },
    {
      tab: 'NOTES',
      content: <Notes />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.NOTES
    },
    {
      tab: 'TASKS',
      content: <TasksTab />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.TASKS
    },
    {
      tab: 'DOCUMENTS',
      content: <DocuementsTab />,
      disabled: isTabDisabled,
      key: CREATE_EVENT_TAB_KEYS.DOCUMENTS
    }
  ];

  return TABS;
};
