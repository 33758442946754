import React, { useState, useEffect } from 'react';
import { Flex } from '@atom/Flex';
import PageLayout from '@layout/PageLayout';
import { PageTitle } from '@molecules/PageTitle';
import { getQuoteManagementTabs, QuoteBasicDetailsObject } from './helper';
import { Progress } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HorizontalTab from '@molecules/HorizontalTabs';
import { updateRawData } from '@utils/index';
import BreadCrumbs from '@atom/BreadCrumb';
import {
  BreadCrumbsEventCreate,
  BreadCrumbsEventFindRouteSelectEditQuote,
  BreadCrumbsEventRouteEventFindQuoteEditQuote,
  QuoteDetailsCreateEventEditQuoteBreadCrumbs,
  QuoteEditQuoteBreadCrumbsRoute
} from '../ViewQuoteDetails/helper';
import { localStorageUtil } from '@utils/localstorageUtil';
import { QuoteViewEventEditEventEditQuoteBreadCrumbs } from '@pages/EventManagementPage/EventDetails/helper';

function AddEditQuote() {
  const navigate = useNavigate();
  const useLocationFromRouter = useLocation();
  const location = localStorageUtil.getValue('location');
  const params = useParams();
  const { quoteId } = params;
  const [progress, setProgress] = useState<number>(0);
  const formType = params.form;

  const [quoteBasicDetails, setQuoteBasicDetails] = useState<QuoteBasicDetailsObject>({});

  const switchTabs = (tab: string) => {
    let progressBar: number = progress;
    if (tab == 'customer') progressBar = 0;
    else if (tab == 'venue') progressBar = 12.5;
    else if (tab == 'meal-plan') progressBar = 25;
    else if (tab == 'meal-order') progressBar = 37.5;
    else if (tab == 'labour') progressBar = 50;
    else if (tab == 'vendor') progressBar = 62.5;
    else if (tab == 'other') progressBar = 75;
    else if (tab == 'fees') progressBar = 87.5;

    setProgress(progressBar);
  };

  const generateUUID = () => {
    // Public Domain/MIT
    let d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    let newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    updateRawData({ random_unique_invoice_string: newGuid });
    return newGuid;
  };

  useEffect(() => {
    if (useLocationFromRouter?.pathname?.includes('edit-quote')) {
      const temp_unique_invoice_string = generateUUID();
      setQuoteBasicDetails((prev) => ({
        ...prev,
        unique_invoice_string: temp_unique_invoice_string
      }));
    }
    return () => {
      updateRawData({ random_unique_invoice_string: null });
    };
  }, []);

  useEffect(() => {
    if (formType) switchTabs(formType);
  }, [formType]);

  const eventId = location?.state?.eventId;
  return (
    <PageLayout>
      {location?.state?.prevPath === '/quote/tab/all' ||
      location?.state?.prevPath?.includes('/quote/add-quote') ? (
        <BreadCrumbs routes={BreadCrumbsEventCreate()} />
      ) : location?.state?.path === 'quote' ? (
        <BreadCrumbs routes={QuoteEditQuoteBreadCrumbsRoute(quoteId)} />
      ) : location?.state?.path === 'quote_details' || location?.state?.path === 'quote_event' ? (
        <BreadCrumbs routes={QuoteViewEventEditEventEditQuoteBreadCrumbs(quoteId, eventId)} />
      ) : location?.state?.path === 'select' || location?.state?.path === 'select_quote' ? (
        <BreadCrumbs routes={BreadCrumbsEventFindRouteSelectEditQuote(quoteId)} />
      ) : typeof location?.state?.path === 'undefined' ||
        location?.state?.path === 'event_details' ||
        location?.state?.path === 'edit_event' ? (
        <BreadCrumbs routes={BreadCrumbsEventRouteEventFindQuoteEditQuote(quoteId, eventId)} />
      ) : location?.state?.path === 'create_event' ? (
        <BreadCrumbs routes={QuoteDetailsCreateEventEditQuoteBreadCrumbs(quoteId)} />
      ) : (
        <BreadCrumbs routes={QuoteEditQuoteBreadCrumbsRoute(quoteId)} />
      )}

      <Flex direction="column" style={{ width: '100%', marginTop: '15px' }}>
        <PageTitle text="QUOTE MANAGEMENT" isBack={false} path={'/quote'} />
        <Flex direction="column" flex={1}>
          <Progress
            strokeColor={progress > 0 && progress < 100 ? '#27a03b' : '#31AF91'}
            percent={progress}
          />
        </Flex>
        <div
          style={{
            marginTop: '18px',
            // background: '#E0E0E0',
            //marginBottom: '43px',
            height: '1px'
          }}
        />

        <HorizontalTab
          value={getQuoteManagementTabs({
            quoteBasicDetails,
            setQuoteBasicDetails,
            quoteId, //@ts-ignore
            tabKey: formType
          })}
          activeKey={formType}
          onChange={(value: any) => {
            const tabBaseUrl = location?.pathname?.includes('edit-quote')
              ? `/quote/edit-quote/${quoteId}/`
              : `/quote/add-quote/${
                  quoteId ? quoteId + '/' : quoteBasicDetails?.id ? quoteBasicDetails?.id + '/' : ''
                }`;
            navigate(`${tabBaseUrl}${value}`, {
              state: { prevPath: location?.pathname, eventId: eventId, path: location?.state?.path }
            });
          }}
        />
      </Flex>
    </PageLayout>
  );
}

export default AddEditQuote;
